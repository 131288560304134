import { euMember } from 'is-european'
import _ from 'lodash'
import {
  AdjustCartParams,
  CartPaymentParams,
  SetUserDataParams,
  SignInParams,
  SubscriberInterface,
  UpdatePrivacyConsentParams,
  ViewItemParams,
} from '../../hooks/events/events.types'
import CartInterface, { CartItemInterface } from '../../interfaces/CartInterface'
import CategoryInterface from '../../interfaces/CategoryInterface'
import ChannelInterface from '../../interfaces/ChannelInterface'
import PrivacyConsentInterface, { PrivacyConsentsFieldEnum } from '../../interfaces/PrivacyConsentInterface'
import ProductInterface, { PriceInterface } from '../../interfaces/ProductInterface'
import { sha256 } from '../../utils/crypto'
import { getPrivacyConsents } from '../../utils/privacyConsent'

type MetaItem = {
  id: string
  sku?: string
  value: number
  quantity: number
  content_category?: string
  content_type?: string
}

const fromBreadcrumbs = (breadcrumbs?: CategoryInterface[]): string => {
  if (!breadcrumbs || breadcrumbs.length <= 1) return ''
  const items = breadcrumbs.map(item => item?.translations['en-AU']?.name).slice(1)
  if (!items) return ''
  return items.join(' > ')
}

const fromCartItem = (item: CartItemInterface): MetaItem => {
  return {
    id: item.skuCode,
    value: parseFloat(item.priceGross) || 0,
    quantity: item.quantity,
    content_category: fromBreadcrumbs(item?.product?.parent?.breadcrumbs),
  }
}

const fromCartItems = (cart: CartInterface) => {
  return cart.items.map(fromCartItem)
}

const fromCart = (cart: CartInterface) => {
  return {
    currency: cart.currency,
    value: cart.totalGross,
    contents: fromCartItems(cart),
    content_ids: cart.items.map(item => item.skuCode),
    content_type: 'product',
    num_items: cart.items.length,
  }
}

const fromProduct = (product: ProductInterface, variantId: number, currency: string, quantity: number = 1): MetaItem | undefined => {
  const variant: ProductInterface | undefined = _.find(product.variants, (_variant: ProductInterface): boolean => _variant.id === variantId)
  if (!variant) return undefined

  const price: PriceInterface | undefined = _.find(variant.pricing, (_price: PriceInterface): boolean => _price.currency === currency)

  return {
    id: String(variant.id),
    sku: variant.skuCode,
    value: parseFloat(price?.grossPrice || '0') || 0,
    quantity,
    content_category: fromBreadcrumbs(product.breadcrumbs),
    content_type: 'product',
  }
}

class MetaTracker implements SubscriberInterface {
  private pixelId: string

  constructor(private _pixelId: string) {
    this.pixelId = _pixelId
  }

  track = (event: string, data: any) => {
    const consents = getPrivacyConsents()
    if (consents && !consents[PrivacyConsentsFieldEnum.FUNCTIONAL]) return
    fbq('track', event, data)
  }

  init = (): void => {
    fbq('init', this.pixelId)
    fbq('track', 'PageView')
  }

  signUp = (data: SignInParams): void => {
    this.track('Lead', {})
  }

  clickCheckout = (cart: CartInterface): void => {
    this.track('InitiateCheckout', {
      ...fromCart(cart),
    })
  }

  adjustCart = (data: AdjustCartParams): void => {
    console.log('Meta adjust cart', data)
    if (data.adjQty === 0) return
    const eventName = data.adjQty > 0 ? 'AddToCart' : 'RemoveFromCart'
    let value = 0
    let items: MetaItem[] = []

    if (data.item) {
      value = parseFloat(data.item.costGross) || 0
      items = [fromCartItem(data.item)]
    } else if (data.product && data.variantId) {
      const MetaItem = fromProduct(data.product, data.variantId, data.currency, data.adjQty)
      if (!MetaItem) return
      items = [MetaItem]
      value = MetaItem.value * MetaItem.quantity
    } else {
      return
    }

    this.track(eventName, {
      content_type: 'product',
      currency: data.currency,
      value,
      content_ids: items.map(item => item.sku),
      contents: items,
      num_items: items.length,
    })
  }

  viewItem = (data: ViewItemParams): void => {
    const MetaItem = fromProduct(data.product, data.product.variants[0].id, data.currency)
    if (!MetaItem) return
    //content_ids, content_type, contents, currency, value
    this.track('ViewContent', {
      currency: data.currency,
      value: MetaItem.value * MetaItem.quantity,
      items: [MetaItem],
      content_type: 'product',
      content_ids: [MetaItem.sku],
      contents: [MetaItem],
      num_items: 1,
    })
  }

  purchase = (order: CartInterface): void => {
    this.track('Purchase', {
      ...fromCart(order),
    })
  }
}

export default (pixelId?: string): SubscriberInterface => {
  if (!pixelId) return {} as SubscriberInterface // return phantom object if no tracking id
  return new MetaTracker(pixelId)
}
